import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainMenuItem } from '@core/entities/site/main-menu.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { MainMenuLoggedInContext } from '@core/main-menu-contexts/logged-in/main-menu-logged-in-context.service';
import { SessionService } from '@core/services/user/session.service';
import { UserService } from '@core/services/user/user.service';
import {
  FfLoadingScreenService,
  LoadingScreenDependencies,
} from '@shared/components/ff-loading-screen/ff-loading-screen.service';
import { FfTooltipArrowPositions } from '@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum';
import { from } from 'rxjs';
import { concatMap, filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ff-header',
  templateUrl: './ff-header.component.html',
  styleUrls: ['./ff-header.component.scss'],
})
export class FfHeaderComponent implements OnInit {
  arrowPos = FfTooltipArrowPositions.topRight;
  impersonating: boolean = false;
  menuHover: boolean = false;
  menuOpen: boolean = false;
  user: OldUserEntity;

  constructor(
    private _loadingScreenService: FfLoadingScreenService,
    private _router: Router,
    private _sessionService: SessionService,
    private _userService: UserService,
    public mainMenuContext: MainMenuLoggedInContext,
  ) {}


  ngOnInit() {
    this._sessionService.isImpersonating().subscribe((isImpersonating: boolean) => {
      this.impersonating = isImpersonating;
    });

    this._userService
      .getOldSelf()
      .pipe(
        filter((user: OldUserEntity | null) => {
          return user !== null;
        }),
      )
      .subscribe((user: OldUserEntity | null) => {
        this.user = user;
      });
  }

  /**
   * @param {MainMenuItem} item
   */
  menuClicked(item: MainMenuItem) {
    // Leave menu open if the clicked menu has submenu or lacks route
    this.menuOpen = item.hasChildren() || !item.route;
  }


  stopImpersonating() {
    this._loadingScreenService.addDependant(LoadingScreenDependencies.IMPERSONATING);

    this._sessionService
      .stopImpersonatingUser()
      .pipe(
        concatMap(() => {
          return from(this._router.navigate(['/users'])).pipe(
            tap(() => {
              this._loadingScreenService.removeDependant(LoadingScreenDependencies.IMPERSONATING);
            }),
          );
        }),
        take(1), // Set finished to prevent future triggering
      )
      .subscribe();
  }
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CorsUrl } from '@core/models/oidc/url/cors-url';

@Component({
  standalone: true,
  selector: 'app-cors-url-list-cors-url-entry',
  templateUrl: './cors-url-list-cors-url-entry.component.html',
  styleUrls: ['./cors-url-list-cors-url-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorsUrlListCorsUrlEntryComponent {
  corsUrl = input.required<CorsUrl>();
}

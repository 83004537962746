import { Component, Input } from '@angular/core';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { UserVerificationHelper } from '@core/helpers/user-verification.helper';

@Component({
  selector: 'app-user-verification-info',
  templateUrl: './user-verification-info.component.html',
  styleUrls: ['./user-verification-info.component.scss'],
})
export class UserVerificationInfoComponent {
  @Input()
  user: OldUserEntity;

  verificationHelper = UserVerificationHelper;
}

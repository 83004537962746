import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, fromEvent, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { MainMenuContextInterface } from '../main-menu/main-menu-context-interface';
import { MainMenuItem } from '@core/entities/site/main-menu.entity';
import { Router } from '@angular/router';
import { FfTypeaheadComponent } from '../ff-typeahead/ff-typeahead.component';
import { WindowRefHelper } from '@core/helpers/window-ref.helper';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @Input() menuContext: MainMenuContextInterface = null;

  @ViewChild('typeheadRef') typeheadRef: FfTypeaheadComponent;

  searchOptions$: Observable<MainMenuItem[]>;
  searchControl = new FormControl<MainMenuItem>(null);
  isMacOS = false;

  constructor(
    private readonly _router: Router,
    @Inject(WindowRefHelper.WINDOW) private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  private readonly _onDestroy$ = new Subject<void>();

  ngOnInit(): void {
    this._setupSubscriptions();
    // @ts-ignore
    let platform = this._window.navigator.userAgentData?.platform; // not fully supported solution
    if (!platform) {
      platform = this._window.navigator.platform; // deprecated solution
    }

    if (platform.toLowerCase().includes('mac')) {
      this.isMacOS = true;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  navigate(item?: MainMenuItem) {
    if (item) {
      this._router.navigate([item.route ?? item.children[0].route]).then(() => {
        this._document.getElementById('main').focus();
      });
    }
  }

  private _setupSubscriptions(): void {
    this.searchOptions$ = this.menuContext.getMainMenuItems().pipe(
      map((menuItems) => {
        return menuItems.reduce<MainMenuItem[]>((items, item) => {
          items.push(...item.children);
          items.push(item);
          return items;
        }, []);
      }),
    );

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        tap((menuItem) => {
          this.navigate(menuItem);
        }),
      )
      .subscribe();

    fromEvent(this._window, 'keydown')
      .pipe(
        takeUntil(this._onDestroy$),
        filter((event: KeyboardEvent) => (event.metaKey && event.key === 'k') || (event.ctrlKey && event.key == 'k')),
        tap((event) => {
          event.preventDefault();
          this.typeheadRef.focusInputRaw();
        }),
      )
      .subscribe();
  }
}

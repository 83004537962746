import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { EnvironmentHelper as EnvHelper, EnvironmentHelper } from '@core/helpers/environment.helper';
import { StringHelper } from '@core/helpers/string.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import { GetScopesResponseDto } from '@core/dtos/id-api/scopes/get-scopes.response-dto';

@Injectable({ providedIn: 'root' })
export class ScopeService {
  readonly #http = inject(HttpClient);

  searchClientScopes(query: string, limit: number, clientUuid: string): Observable<GetScopesResponseDto> {
    let params = new HttpParams();
    params = params
      .set('query', query)
      .set('limit', limit.toString())
      .set('clientUuid', clientUuid);

    return this.#http
      .get<GetScopesResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_SCOPES.path,
        ).toString(),
        {
          params,
        });
  }

  getClientsUsingScope(scopeUuid: string): Observable<ClientEntity[]> {
    return this.#http.get(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeUuid + '/clients')).pipe(
      map((response: { clients: [] }) => {
        if (!response) {
          return [];
        }

        return deserialize(ClientEntity, response.clients);
      }),
    );
  }

  getGlobalScopes(): Observable<ScopeEntity[]> {
    return this.#http
      .get(EnvironmentHelper.fetchAPIBase('v1/scopes/global'), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<{ scopes: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(ScopeEntity, response.body.scopes);
          }
        }),
      );
  }

  getScope(scopeId: string): Observable<ScopeEntity> {
    return this.#http.get(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeId)).pipe(
      map((response: { scope: object }) => {
        return deserialize(ScopeEntity, response.scope);
      }),
    );
  }

  deleteScope(scopeUuid: string): Observable<{}> {
    return this.#http.delete(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeUuid));
  }

  deleteScopeExpiration(scopeExpirationUuid: string): Observable<{}> {
    return this.#http.delete(EnvironmentHelper.fetchAPIBase('v1/scope-expiration-dates/' + scopeExpirationUuid));
  }

  existsByName(scope: string): Observable<boolean> {
    return this.#http
      .get(EnvHelper.fetchAPIBase('v2/scopes/exists/by-name?name=' + StringHelper.fixedEncodeURIComponent(scope)))
      .pipe(
        map((response: { exists: boolean }) => {
          return response.exists;
        }),
      );
  }

  patchScope(scopeId: string, property: string, value: any): Observable<ScopeEntity> {
    return this.#http
      .patch(EnvironmentHelper.fetchAPIBase('v1/scopes/') + scopeId, {
        attribute: property,
        value,
      })
      .pipe(
        map((response: { scope: object }) => {
          return deserialize(ScopeEntity, response.scope);
        }),
      );
  }

  postScope(serviceId: string, scope: ScopeEntity): Observable<ScopeEntity> {
    return this.#http
      .post(EnvironmentHelper.fetchAPIBase('v1/scopes'), {
        scope: serialize(scope),
        apiUuid: serviceId,
      })
      .pipe(
        map((response: { scope: object }) => {
          return deserialize(ScopeEntity, response.scope);
        }),
      );
  }

  postScopeExpiration(scopeUuid: string, notes: string, deprecatedSince: string, sunset?: string): Observable<object> {
    return this.#http
      .post(EnvironmentHelper.fetchAPIBase('v1/scope-expiration-dates'), {
        scopeExpirationDate: {
          notes,
          deprecatedSince,
          sunset,
        },
        scopeUuid,
      })
      .pipe(
        map((response: { scopeExpiration: object }) => {
          return response.scopeExpiration;
        }),
      );
  }
}

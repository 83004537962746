@let c = client();

@if (enableFiltering) {
  <div class="filtering form">
    <div class="filters">
      <div class="form-group">
        <div class="form-control form-control--dual">
          <label for="filter-scope-name">Filtrer på navn</label>
          <app-ff-icon-input
            [iconClassList]="'s20 monochrome stroke-text-50'"
            [iconPath]="'assets/icons/spyglass.svg'"
            tooltipText="Søk"
            >
            <input
              [formControl]="rfh.getCtrl(filterForm, 'query')"
              class="input"
              id="filter-scope-name"
              inputmode="search"
              placeholder="Scopenavn / beskrivelse"
              type="text"
              />
          </app-ff-icon-input>
        </div>
        @if (!c) {
          <div class="form-control form-control--dual prefixes">
            <label>Scope prefix</label>
            <app-ff-checkbox
              (changed)="togglePrefixes()"
              labelText="Skjul prefix"
              title="Skjul prefix i scopenavn, alt frem t.o.m første '.'"
            ></app-ff-checkbox>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (!(scopesFiltered$ | async).length) {
  <div class="scope well well--padded">
    Ingen matchende scopes. @if (enableFiltering) {
    <span>Vennligst prøv andre filtre.</span>
  }
</div>
}

@if (collapsible) {
  <div class="buttons buttons--inline collapse-toggles">
    <app-ff-button (clicked)="expandAll()" class="inline micro" color="beige" label="Utvid alle"></app-ff-button>
    <app-ff-button (clicked)="collapseAll()" class="inline micro" color="beige" label="Minimer alle"></app-ff-button>
  </div>
}

<ul class="scopes" [class.collapsible]="collapsible">
  @for (scope of scopesFiltered$ | async; track scope) {
    <li
      class="scope well"
      [class.expanded]="expandedItems[scope.uuid]"
      [class.highlight]="scope.highlight"
      [class.locked]="!canBeManaged(scope)"
      >
      @if (c && (!collapsible || expandedItems[scope.uuid])) {
        <app-ff-delete-button
          (clicked)="confirmRemoval(scope)"
          [disabled]="!canBeManaged(scope)"
          [processing]="scope.processing"
          [titleText]="canBeManaged(scope) ? textRemove : textCannotRemove"
          class="scope__delete"
        ></app-ff-delete-button>
      }
      <!-- Collapsed view -->
      @if (collapsible && !expandedItems[scope.uuid]) {
        <header class="collapsed">
          <app-ff-button
            (clicked)="expand(scope)"
            class="inline padless expand-toggle"
            color="transparent"
            [iconSize]="22"
            iconPath="icons/caret_down.svg"
          ></app-ff-button>
          <div class="scope__name">
            {{ scope.name }}
            @if (scope.scopeExpirationDate?.uuid) {
              <svg-icon
                [applyClass]="true"
                [class.scope__deprecation_icon--warning]="pastSunset(scope)"
                class="scope__deprecation_icon"
                src="assets/icons/warning_triangle.svg"
                role="presentation"
                title="Utfases, utvid for detaljer"
              ></svg-icon>
            }
          </div>
          @if (c) {
            <div class="scope__grants-summary">
              @if (scope.required) {
                <abbr title="Scopet er obligatorisk">OB</abbr>
              }
              @for (grant of grants; track grant) {
                @if (scopeHasGrant(scope, grant)) {
                  <abbr title="Brukes ved {{ grant.name }} grant" class="grant">
                    {{ grantAbbreviations[grant.name] }}
                  </abbr>
                }
              }
            </div>
          }
        </header>
      }
      <!-- Expanded view -->
      @if (!collapsible || expandedItems[scope.uuid]) {
        @if (collapsible) {
          <app-ff-button
            (clicked)="collapse(scope)"
            class="inline padless collapse-toggle"
            color="transparent"
            [iconSize]="22"
            iconPath="icons/caret_up.svg"
          ></app-ff-button>
        }
        @if (!collapsible && scope.scopeExpirationDate?.uuid) {
          <app-ff-tooltip
            [options]="deprecationTooltipOpts"
            [text]="deprecationText(scope)"
            >
            <svg-icon
              [applyClass]="true"
              [class.scope__deprecation_icon--warning]="pastSunset(scope)"
              class="scope__deprecation_icon"
              src="assets/icons/warning_triangle.svg"
              role="presentation"
            ></svg-icon>
          </app-ff-tooltip>
        }
        <a [routerLink]="routePrefix && isAppAdmin ? routePrefix + scope.uuid : null" class="scope__name">
          {{ showPrefixes ? scope.name : scope.name.substring(scope.name.indexOf('.') + 1) }}
        </a>
        <div class="scope__description" [innerHTML]="scope.description | nl2br"></div>
        @if (collapsible && scope.scopeExpirationDate?.uuid) {
          <div class="scope__deprecation">
            <svg-icon
              [applyClass]="true"
              [class.scope__deprecation_icon--warning]="pastSunset(scope)"
              class="scope__deprecation_icon"
              src="assets/icons/warning_triangle.svg"
              role="presentation"
            ></svg-icon>
            {{ deprecationText(scope) }}
          </div>
        }
        @if (c) {
          <div class="scope__required">
            @if (canBeManaged(scope)) {
              <app-ff-checkbox
                (changed)="toggleRequired.emit(scope)"
                [checked]="scope.required"
                [disabled]="scope.processing"
                helpText="Scope som settes obligatorisk kan ikke velges bort når bruker skal samtykke til hva klienten får tilgang til"
                labelText="Obligatorisk"
              ></app-ff-checkbox>
            }
            @if (!canBeManaged(scope)) {
              <div class="scope__required__property" title="Scopet er låst og kan ikke endres">
                <svg-icon
                  [applyClass]="true"
                  class="s18 disabled"
                  role="presentation"
                  src="assets/icons/check_mark.svg"
                ></svg-icon>
                <span class="scope__required__property__description">{{
                  scope.required ? 'Obligatorisk' : 'Valgfritt'
                }}</span>
              </div>
            }
          </div>
          <div class="scope__grants">
            @for (grant of grants; track grant) {
              @if (grantIsAvailable(grant)) {
                <app-ff-checkbox
                  (changed)="toggleGrant(scope, grant)"
                  [checked]="scopeHasGrant(scope, grant)"
                  [processing]="processingToggles['scope.' + scope.uuid + '.grant.' + grant.uuid]"
                  [labelText]="'Brukes ved ' + grant.name + ' grant'"
                  [disabled]="!isAppAdmin || scope.processing"
                  [helpText]="!isAppAdmin ? 'Kan kun endres av Fagforbundet' : ''"
                ></app-ff-checkbox>
              }
            }
          </div>
        }
      }
    </li>
  }
</ul>

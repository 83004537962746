import { Injectable } from '@angular/core';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _user$: BehaviorSubject<OldUserEntity> = new BehaviorSubject<OldUserEntity>(null);
  private readonly _impersonating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  user$ = this._user$.asObservable();

  constructor() {
    this._impersonating$.next(!!localStorage.getItem('impersonating'));
  }

  set impersonating(isImpersonating: boolean) {
    if (!isImpersonating) {
      if (!!localStorage.getItem('impersonating')) {
        localStorage.removeItem('impersonating');
      }
    } else {
      localStorage.setItem('impersonating', JSON.stringify(isImpersonating));
    }

    this._impersonating$.next(isImpersonating);
  }

  get user(): OldUserEntity | null {
    return this._user$.value;
  }

  set user(user: OldUserEntity | null) {
    this._user$.next(user);
  }

  isImpersonating(): Observable<boolean> {
    return this._impersonating$;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PostalCodeLookupResponse } from '@core/entities/response/postal-lookup-response.entity';
import { Country } from '@core/entities/user/country.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { AddressLookupService } from '@core/services/address/address-lookup.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { UserFormBuilder } from '../user-form.factory';
import { AddressForm } from '../types/address-form.type';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnDestroy, OnInit {
  @Input()
  formGroup = this._ufb.Address({});

  @Input()
  index: number;

  @Input()
  user: OldUserEntity;

  countries$: Observable<Country[]>;
  processing = false;
  controls: FormGroup<AddressForm>['controls'];

  private readonly _onDestroy$ = new Subject<void>();

  private _countriesSubject$ = new BehaviorSubject<Country[]>([]);

  constructor(private _addressLookupService: AddressLookupService, private _ufb: UserFormBuilder) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    this.controls = this.formGroup.controls;

    this.controls.countryObject.valueChanges
      .pipe(
        tap((val) => {
          if (val) {
            this.controls.country.setValue(val.name);
            this.controls.countryCode.setValue(val.code);
            this.formGroup.markAsDirty();
          }
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe();

    this.countries$ = this._countriesSubject$.asObservable();
    this._fetchCountries();
  }

  line3Check() {
    if (!this.controls.line2.value || this.controls.line2.value === '') {
      this.controls.line3.setValue(null);
    }
  }

  postalLookupCallback(event: PostalCodeLookupResponse): void {
    if (event.valid && event.postalArea) {
      this.controls.postalArea.setValue(event.postalArea);
      this.controls.postalArea.setErrors(null);
    } else {
      this.controls.postalArea.setValue('');
      this.controls.postalArea.setErrors({ required: true });
    }
  }

  private _fetchCountries(ietfCode: string = 'nb-NO'): void {
    this.processing = true;
    this._addressLookupService
      .getCountries(ietfCode)
      .pipe(
        tap((countries: Country[]) => {
          this._countriesSubject$.next(deserialize(Country, countries));

          let initialCountry: Country;

          if (this.controls.country.value || this.controls.countryObject.value) {
            const existing = this.controls.country.value
              ? this.controls.country.value
              : this.controls.countryObject.value.name;
            initialCountry = countries.find((country: Country) => {
              return country.name.toUpperCase() === existing.toUpperCase();
            });
          } else {
            initialCountry = countries.find((country: Country) => {
              return country.code.toUpperCase() === 'NO';
            });
          }

          this.controls.countryObject.setValue(initialCountry);
        }),
        finalize(() => {
          this.processing = false;
        }),
        take(1),
      )
      .subscribe();
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DeveloperEntity } from '@core/entities/api/developer.entity';
import { ModalService } from '@core/services/modal/modal.service';
import {
  FfConfirmModalDataInterface,
} from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface';
import { FfConfirmModalComponent } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component';

@Component({
  selector: "app-developer-list",
  templateUrl: "./developer-list.component.html",
  styleUrls: ["./developer-list.component.scss"],
})
export class DeveloperListComponent implements OnChanges {
  @Input({ required: true })
  developers!: DeveloperEntity[];

  @Output()
  removed: EventEmitter<DeveloperEntity> = new EventEmitter<DeveloperEntity>();

  @Input({ required: false })
  isAppAdmin: boolean = false;

  @Input({ required: false })
  isUserAdmin: boolean = false;

  @Input({ required: false })
  processing: boolean = false;

  @Input({ required: false })
  removeLabel: string = "Fjern tilgang";

  constructor(private _modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["processing"] && !changes["processing"].isFirstChange()) {
      this.processing = !!changes["processing"].currentValue;
    }
  }

  confirmRemoval(dev: DeveloperEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body:
        "Vil du faktisk fjerne utviklertilgangen for «" +
        dev.firstName +
        " " +
        dev.lastName +
        "»?",
      confirmCallback: () => {
        this.removed.emit(dev);
      },
    };

    this._modalService.openModal(confirmContent);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_ENTITY_SCHEMA } from '@core/entities/api/api-client-entity.schema';
import { ApiEntity } from '@core/entities/api/api.entity';
import { DeveloperEntity } from '@core/entities/api/developer.entity';
import { RevokeUrlEntity } from '@core/entities/api/revoke-url.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import { PostAddClientApiResponseDto } from '@core/dtos/id-api/clients/oauth/apis/post-add-client-api.response-dto';
import {
  DeleteRemoveClientApiResponseDto
} from '@core/dtos/id-api/clients/oauth/apis/delete-remove-client-api.response-dto';
import { GetApisResponseDto } from '@core/dtos/id-api/apis/get-apis.response-dto';

@Injectable({ providedIn: 'root' })
export class ApiService {
  readonly #httpClient = inject(HttpClient);

  assignClient(apiUuid: string, clientUuid: string): Observable<PostAddClientApiResponseDto> {
    return this.#httpClient
      .post<PostAddClientApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_CLIENT_API.path,
          {
            apiUuid,
            clientUuid,
          },
        ).toString(),
        {},
      );
  }

  unAssignClient(apiUuid: string, clientUuid: string): Observable<DeleteRemoveClientApiResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_CLIENT_API.path,
          {
            apiUuid,
            clientUuid,
          },
        ).toString(),
        {},
      );
  }

  addDeveloper(api: ApiEntity, user: OldUserEntity): Observable<ApiEntity> {
    return this.#httpClient.post(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/developers/' + user.uuid), {}).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  deleteRevokeUrl(revokeId: string): Observable<RevokeUrlEntity> {
    return this.#httpClient.delete(EnvironmentHelper.fetchAPIBase('v1/api-revoke-urls/' + revokeId)).pipe(
      map((response: { apiRevokeUrl: object }) => {
        return deserialize(RevokeUrlEntity, response.apiRevokeUrl);
      }),
    );
  }

  deleteApi(api: ApiEntity): Observable<ApiEntity> {
    return this.#httpClient.delete(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid)).pipe(
      map((response: { api: object }) => {
        return deserialize(ApiEntity, response.api);
      }),
    );
  }

  getApi(apiId: string): Observable<ApiEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('v1/apis/' + apiId)).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  getApis(
    limit: number = 20,
    offset: number = 0,
    query?: string,
    onlyAccessible: boolean = true,
  ): Observable<GetApisResponseDto> {
    let params = new HttpParams();
    params = params.set('limit', limit);
    params = params.set('offset', offset);

    if (query) {
      params = params.set('query', query);
    }

    params = params.set('only-accessible', onlyAccessible.toString());

    return this.#httpClient
      .get<GetApisResponseDto>(EnvironmentHelper.fetchAPIBase('v1/apis'), {
        params,
      });
  }

  postRevokeUrl(apiId: string, url: RevokeUrlEntity): Observable<RevokeUrlEntity> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase('v1/api-revoke-urls'), {
        apiUuid: apiId,
        apiRevokeUrl: serialize(url),
      })
      .pipe(
        map((response: { apiRevokeUrl: object }) => {
          return deserialize(RevokeUrlEntity, response.apiRevokeUrl);
        }),
      );
  }

  postApi(api: ApiEntity): Observable<ApiEntity> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase('v1/apis'), {
        api: serialize(api),
      })
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }

  putApi(apiId: string, api: ApiEntity): Observable<ApiEntity> {
    return this.#httpClient
      .put(EnvironmentHelper.fetchAPIBase('v1/apis/' + apiId), {
        api: serialize(api),
      })
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }

  removeDeveloper(api: ApiEntity, developer: DeveloperEntity): Observable<ApiEntity> {
    return this.#httpClient
      .delete(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/developers/' + developer.uuid), {})
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }
}

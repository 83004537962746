import { Component, Input } from '@angular/core';
import { OldUserEntity } from '@core/entities/user/user.entity';

@Component({
  selector: 'app-user-account-status-info',
  templateUrl: './user-account-status-info.component.html',
  styleUrls: ['./user-account-status-info.component.scss'],
})
export class UserAccountStatusInfoComponent {
  @Input()
  user: OldUserEntity;
}

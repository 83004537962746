<ul class="clients">
  @if (!clients() || clients().length < 1) {
    <li class="client well">
      <span>Ingen klienter</span>
    </li>
  }

  @for (client of clients(); track client.uuid) {
    <li class="client well">
      <div class="client__info">
        <div class="client__name">
          @if (linkedTitle()) {
            <a [routerLink]="'/clients/' + client.type + '/' + client.uuid">{{ client.name }}</a>
          }
          @if (!linkedTitle()) {
            <span>{{ client.name }}</span>
          }
        </div>
        <div class="client__details">
          <div class="detail detail--description">
            <img src="assets/icons/document_a_24.svg" alt="Ikon" role="presentation" />
            <span>{{ client.description }}</span>
          </div>
          <div class="detail detail--client-type">
            @if (client.type === 'sso') {
              <img src="assets/icons/key.svg" alt="Klienttypeikon" role="presentation" />
            }
            @if (client.type === 'oauth') {
              <img src="assets/icons/openid.svg" alt="Klienttypeikon" role="presentation" />
            }
            <span>{{ client.type === 'oauth' ? 'OAuth' : 'SSO' }}</span>
          </div>
          <div class="detail detail--client-active">
            @if (client.active) {
              <ff-ngx-icon name="16x16/verified" [size]="16" stroke="#3c3c3b" />
            } @else {
              <ff-ngx-icon name="16x16/close" [size]="16" stroke="#3c3c3b" />
            }
            <span>{{ client.active ? 'Aktiv' : 'Inaktiv' }}</span>
          </div>
          <div class="detail detail--client-devmode">
            @if (client.devMode) {
              <img src="assets/icons/person_hardhat.svg" alt="Ikon" role="presentation" />
            }
            @if (client.devMode) {
              <span>DevMode</span>
            }
          </div>
        </div>
      </div>
      @if (!hideDeleteBtn()) {
        <button
          ffNgxButton
          (click)="confirmRemoval(client)"
          [disabled]="!linkedTitle()"
        >Fjern
        </button>
      }
    </li>
  }
</ul>

@if (processing()) {
  <div class="processing">
    <ff-ngx-loading-indicator stroke="#2f2f2f" size="large" />
  </div>
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ArrayHelper {
  /**
   * Use this as callback for .sort() to easily sort by multiple fields
   *
   * @example const fastestCars = cars.sort(fieldSorter(['-topSpeed', '-!price']));
   * @param {Array} fields Array of field (property) names to sort by.
   *    Prefix fieldname with dash to sort descending.
   *    Prefix fieldname with exclamation mark to only check for existence
   *    Combine them in order -!
   * @returns {(a, b) => any}
   */
  static fieldSorter = (fields: string[]) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }

        if (o[0] === '!') {
          o = o.substring(1);
          return a[o] && !b[o] ? dir : !a[o] && b[0] ? -dir : 0;
        }

        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  static removeIfExists<T>(haystack: T[], needle: T, compareProperty = 'uuid'): boolean {
    const idx = haystack.findIndex((stack) => stack[compareProperty] === needle[compareProperty]);
    if (idx < 0) {
      return false;
    }

    haystack.splice(idx, 1);
    return true;
  }

  static unique<T>(array: T[]): T[] {
    return array.filter((v, i, a) => a.indexOf(v) === i);
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Role } from '@core/entities/user/role.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { RoleHelper } from '@core/helpers/role.helper';
import { UserFormControlsEnum } from '@shared/components/user-form/user-form-controls.enum';
import { UserFormBuilder } from '../user-form.factory';
import { UserPermissionForm } from '../types/user-permission-form.type';

@Component({
  selector: 'app-user-permission-form',
  templateUrl: './user-permission-form.component.html',
  styleUrls: ['./user-permission-form.component.scss'],
})
export class UserPermissionFormComponent implements OnInit, OnChanges {
  @Input()
  roles: Role[];

  @Input()
  rolesFormArray: FormArray<FormGroup<UserPermissionForm>>;

  @Input()
  selfUser: OldUserEntity;

  @Input()
  userToBeEdited: OldUserEntity;

  rfh = ReactiveFormsHelper;

  userFormControls = UserFormControlsEnum;

  constructor(private _ufb: UserFormBuilder) {}

  /**
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userToBeEdited'] && !changes['userToBeEdited'].isFirstChange()) {
      this.userToBeEdited = changes['userToBeEdited'].currentValue;
      this._addRolesToForm();
    }
  }

  ngOnInit() {
    this._addRolesToForm();
  }

  /**
   * @returns {boolean}
   */
  isUserAdmin(): boolean {
    return RoleHelper.isUserAdmin(this.selfUser);
  }

  /**
   * @private
   */
  private _addRolesToForm() {
    this.roles.forEach((role) => {
      this.rolesFormArray.push(this._ufb.Role(role, this.userToBeEdited.roles));
    });

    if (!RoleHelper.isUserAdmin(this.selfUser)) {
      this.rolesFormArray.disable({ onlySelf: false });
    }
  }
}

import { Address } from '@core/entities/user/address.entity';
import { Email } from '@core/entities/user/email.entity';
import { Phone } from '@core/entities/user/phone.entity';
import { Role } from '@core/entities/user/role.entity';
import { UserVerificationEntity } from '@core/entities/user/user-verification.entity';
import { UserAccountStatusesEnum } from '@core/enums/user-account-statuses.enum';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { alias, identifier, list, object, serializable } from 'serializr';

export class OldUserEntity implements ProcessableInterface {
  processing: boolean;

  @serializable(alias('accountStatus'))
  private _accountStatus: UserAccountStatusesEnum;

  @serializable(alias('postalAddresses', list(object(Address))))
  private _postalAddresses: Address[] = [];

  @serializable(alias('bankAccount'))
  private _bankAccount: string;

  @serializable(alias('emailAddresses', list(object(Email))))
  private _emailAddresses: Email[] = [];

  @serializable(alias('firstName'))
  private _firstName: string;

  @serializable(alias('testUser'))
  private _testUser: boolean = false;

  @serializable(alias('lastName'))
  private _lastName: string;

  @serializable(alias('memberId'))
  private _memberId?: string;

  @serializable(alias('phoneNumbers', list(object(Phone))))
  private _phoneNumbers: Phone[] = [];

  @serializable(alias('nin'))
  private _nin: string;

  @serializable(alias('roles', list(object(Role))))
  private _roles: Role[] = [];

  @serializable(alias('passwordSet'))
  private _passwordSet: boolean;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('verifications', list(object(UserVerificationEntity))))
  private _verifications: UserVerificationEntity[] = [];

  /**
   * @returns {boolean}
   */
  get isActive(): boolean {
    return this.accountStatus === UserAccountStatusesEnum.ACTIVATED;
  }

  /**
   * @returns {boolean}
   */
  get isVerified(): boolean {
    return this._verifications.length > 0;
  }

  /**
   * Return full name concatenated
   *
   * @returns {string}
   */
  get name(): string {
    const first = this.firstName ? this.firstName : '';
    const last = this.lastName ? this.lastName : '';
    return first + ' ' + last;
  }

  /**
   *
   * @returns {UserAccountStatusesEnum}
   */
  get accountStatus(): UserAccountStatusesEnum {
    return this._accountStatus;
  }

  /**
   *
   * @param {UserAccountStatusesEnum} value
   */
  set accountStatus(value: UserAccountStatusesEnum) {
    this._accountStatus = value;
  }

  /**
   * @returns {Address[]}
   */
  get postalAddresses(): Address[] {
    return this._postalAddresses;
  }

  /**
   * @param {Address[]} value
   */
  set postalAddresses(value: Address[]) {
    this._postalAddresses = value;
  }

  /**
   * @returns {string}
   */
  get bankAccount(): string {
    return this._bankAccount;
  }

  /**
   * @param bankAccount
   */
  set bankAccount(bankAccount: string) {
    this._bankAccount = bankAccount;
  }

  /**
   * @returns {Email[]}
   */
  get emails(): Email[] {
    return this._emailAddresses;
  }

  /**
   * @param {Email[]} value
   */
  set emails(value: Email[]) {
    this._emailAddresses = value;
  }

  /**
   * @returns {string}
   */
  get firstName(): string {
    return this._firstName;
  }

  /**
   * @param firstName
   */
  set firstName(firstName: string) {
    this._firstName = firstName;
  }

  /**
   * @returns {boolean}
   */
  get isTestUser(): boolean {
    return this._testUser;
  }

  /**
   * @param isTestUser
   */
  set isTestUser(isTestUser: boolean) {
    this._testUser = isTestUser;
  }

  /**
   * @returns {string}
   */
  get lastName(): string {
    return this._lastName;
  }

  /**
   * @param lastName
   */
  set lastName(lastName: string) {
    this._lastName = lastName;
  }

  /**
   * @returns {string}
   */
  get memberId(): string {
    return this._memberId;
  }

  /**
   * @param {string} value
   */
  set memberId(value: string) {
    this._memberId = value;
  }

  /**
   * @returns {Phone[]}
   */
  get phones(): Phone[] {
    return this._phoneNumbers;
  }

  /**
   * @param {Phone[]} value
   */
  set phones(value: Phone[]) {
    this._phoneNumbers = value;
  }

  /**
   * @returns {string}
   */
  get nin(): string {
    return this._nin;
  }

  /**
   * @param rawSsn
   */
  set nin(rawSsn: string) {
    this._nin = rawSsn;
  }

  /**
   * @returns {Role[]}
   */
  get roles(): Role[] {
    return this._roles;
  }

  /**
   * @param {Role[]} value
   */
  set roles(value: Role[]) {
    this._roles = value;
  }

  /**
   * @returns {boolean}
   */
  get tfaActivated(): boolean {
    return this._passwordSet;
  }

  /**
   * @param tfaActivated
   */
  set tfaActivated(tfaActivated: boolean) {
    this._passwordSet = tfaActivated;
  }

  /**
   * @returns {string}
   */
  get uuid(): string {
    return this._uuid;
  }

  /**
   * @returns {UserVerificationEntity[]}
   */
  get verifications(): UserVerificationEntity[] {
    return this._verifications;
  }

  /**
   * @param {UserVerificationEntity[]} value
   */
  set verifications(value: UserVerificationEntity[]) {
    this._verifications = value;
  }
}

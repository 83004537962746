import { OldUserEntity } from '@core/entities/user/user.entity';
import { UserInterface } from '@core/entity-interfaces/user/user.interface';
import { User } from '@core/models/user/user';
import { Roles } from '@core/models/user/roles';

export class RoleHelper {
  static isAnyAdmin(user: User | OldUserEntity | UserInterface | { roles: { role: string }[] }): boolean {
    return (
      RoleHelper.hasRole(user, Roles.ROLE_APP_ADMIN) ||
      RoleHelper.hasRole(user, Roles.ROLE_SYSTEM_ADMIN) ||
      RoleHelper.hasRole(user, Roles.ROLE_USER_ADMIN)
    );
  }

  static isAppAdmin(user: User | OldUserEntity | UserInterface | { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_APP_ADMIN);
  }

  static isSysAdmin(user: User | OldUserEntity | UserInterface | { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_SYSTEM_ADMIN);
  }

  static isUserAdmin(user: User | OldUserEntity | UserInterface | { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_USER_ADMIN);
  }

  static hasRole(user: User | OldUserEntity | UserInterface | { roles: { role: string }[] }, role: Roles): boolean {
    for (const r of user.roles) {
      if (r.role === role) {
        return true;
      }
    }

    return false;
  }
}

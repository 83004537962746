import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MainMenuItem } from '@core/entities/site/main-menu.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { RoleHelper } from '@core/helpers/role.helper';
import { MainMenuCommonContext } from '@core/main-menu-contexts/common/main-menu-common-context';
import { SessionService } from '@core/services/user/session.service';
import { UserService } from '@core/services/user/user.service';
import {
  FfLoadingScreenService,
  LoadingScreenDependencies,
} from '@shared/components/ff-loading-screen/ff-loading-screen.service';
import { MainMenuContextInterface } from '@shared/components/main-menu/main-menu-context-interface';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';
import { Roles } from '@core/models/user/roles';

@Injectable({ providedIn: 'root' })
export class MainMenuLoggedInContext extends MainMenuCommonContext implements MainMenuContextInterface {
  user: OldUserEntity;

  private _impersonating: boolean = false;
  private _mainMenuItems$: ReplaySubject<MainMenuItem[]> = new ReplaySubject<MainMenuItem[]>(1);

  constructor(
    private _loadingScreenService: FfLoadingScreenService,
    private _router: Router,
    private _sessionService: SessionService,
    private _userService: UserService,
  ) {
    super();
    this._init();
  }

  getMainMenuItems(): Observable<MainMenuItem[]> {
    return this._mainMenuItems$;
  }

  /**
   * @private
   */
  private _init(): void {
    this._userService
      .getOldSelf()
      .pipe(
        concatMap((user: OldUserEntity | null) => {
          if (user) {
            return this._sessionService.isImpersonating().pipe(
              tap((isImpersonating: boolean) => {
                this._impersonating = isImpersonating;
              }),
              map(() => user),
              take(1),
            );
          } else {
            return of(null);
          }
        }),
        filter((user: OldUserEntity | null) => user !== null),
        // Rebuild menu when user changes
        tap((user: OldUserEntity) => {
          this.user = user;
          this._buildMenu();
          this.setActive(this._router.url);
        }),
      )
      .subscribe();

    // Set active item on navigation
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.url !== event.urlAfterRedirects),
        tap((n: NavigationEnd) => {
          this.setActive(n.url);
        }),
      )
      .subscribe();
  }

  /**
   * @private
   */
  private _buildMenu() {
    this.mainMenuItems = [];

    const userMenuItem = new MainMenuItem('Min konto', 'user', 'icons/circled_user.svg');

    userMenuItem.addChild(new MainMenuItem('Brukerprofil', 'profile', undefined, '/user/profile'));
    userMenuItem.addChild(new MainMenuItem('Passord', 'password', undefined, '/user/tfa'));
    userMenuItem.addChild(new MainMenuItem('Deaktiver konto', 'deactivate', undefined, '/user/deactivate'));

    this.mainMenuItems.push(userMenuItem);

    if (
      RoleHelper.hasRole(this.user, Roles.ROLE_USER_ADMIN) ||
      RoleHelper.hasRole(this.user, Roles.ROLE_USER_MANAGER)
    ) {
      const usersMenuItem = new MainMenuItem('Brukere', 'users', 'icons/member.svg', '/users');
      this.mainMenuItems.push(usersMenuItem);
    }

    if (
      RoleHelper.hasRole(this.user, Roles.ROLE_DEVELOPER) ||
      RoleHelper.hasRole(this.user, Roles.ROLE_APP_ADMIN)
    ) {
      const apisMenuItem = new MainMenuItem('API-er', 'apis', 'icons/archive.svg', '/apis');
      this.mainMenuItems.push(apisMenuItem);
    }

    if (
      RoleHelper.hasRole(this.user, Roles.ROLE_DEVELOPER) ||
      RoleHelper.hasRole(this.user, Roles.ROLE_APP_ADMIN)
    ) {
      const servicesMenuItem = new MainMenuItem('Klienter', 'clients', 'icons/document_in_box.svg', '/clients');

      this.mainMenuItems.push(servicesMenuItem);
    }

    // SysConfig
    if (RoleHelper.hasRole(this.user, Roles.ROLE_SYSTEM_ADMIN)) {
      const sysConfigMenuItem = new MainMenuItem('Systeminnstillinger', 'sysconfig', 'icons/cog.svg');

      sysConfigMenuItem.addChild(new MainMenuItem('API-nøkler', 'api-keys', undefined, '/sys-config/api-keys'));
      sysConfigMenuItem.addChild(
        new MainMenuItem('Globale scopes', 'global-scopes', undefined, '/sys-config/global-scopes'),
      );
      sysConfigMenuItem.addChild(
        new MainMenuItem('Integrasjoner', 'integrations', undefined, '/sys-config/integrations'),
      );
      sysConfigMenuItem.addChild(new MainMenuItem('Postnumre', 'postal-codes', undefined, '/sys-config/postal-codes'));

      this.mainMenuItems.push(sysConfigMenuItem);
    }

    // Add log out of user
    if (this._impersonating) {
      const stopImpersonatingMenuItem = new MainMenuItem('Logg ut av bruker', 'logout', undefined, '', () => {
        this.stopImpersonating.call(this);
      });
      this.mainMenuItems.push(stopImpersonatingMenuItem);
    }

    // Finally, add logout
    const logoutMenuItem = new MainMenuItem('Logg ut', 'logout', undefined, '/logout');

    this.mainMenuItems.push(logoutMenuItem);

    this._mainMenuItems$.next(this.mainMenuItems);
  }

  stopImpersonating() {
    this._loadingScreenService.addDependant(LoadingScreenDependencies.IMPERSONATING);

    this._sessionService
      .stopImpersonatingUser()
      .pipe(
        concatMap(() => {
          return from(this._router.navigate(['/users'])).pipe(
            tap(() => {
              this._loadingScreenService.removeDependant(LoadingScreenDependencies.IMPERSONATING);
            }),
          );
        }),
        take(1), // Set finished to prevent future triggering
      )
      .subscribe();
  }
}

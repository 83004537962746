import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import { GetOauthGrantsResponseDto } from '@core/dtos/id-api/grants/get-oauth-grants.response-dto';
import {
  PostAddClientAllowedGrantResponseDto
} from '@core/dtos/id-api/clients/oauth/grants/post-add-client-allowed-grant.response-dto';
import {
  DeleteRemoveClientAllowedGrantResponseDto
} from '@core/dtos/id-api/clients/oauth/grants/delete-remove-client-allowed-grant.response-dto';
import {
  PostAddClientScopeAllowedGrantResponseDto
} from '@core/dtos/id-api/clients/oauth/scopes/post-add-client-scope-allowed-grant.response-dto';
import {
  DeleteRemoveClientScopeAllowedGrantResponseDto
} from '@core/dtos/id-api/clients/oauth/scopes/delete-remove-client-scope-allowed-grant.response-dto';

@Injectable({
  providedIn: "root",
})
export class GrantService {
  readonly #httpClient = inject(HttpClient);

  getOauthGrants(): Observable<GetOauthGrantsResponseDto> {
    return this.#httpClient
      .get<GetOauthGrantsResponseDto>(EnvironmentHelper.fetchAPIBase("v1/oauth-grants"));
  }

  addGrantToClient(
    clientUuid: string,
    grantUuid: string
  ): Observable<PostAddClientAllowedGrantResponseDto> {
    return this.#httpClient
      .post<PostAddClientAllowedGrantResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_CLIENT_ALLOWED_GRANT.path,
          {
            clientUuid,
            grantUuid
          }
        ).toString(),
        {}
      );
  }

  removeGrantFromClient(
    clientUuid: string,
    grantUuid: string
  ): Observable<DeleteRemoveClientAllowedGrantResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientAllowedGrantResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_ALLOWED_GRANT.path,
          {
            clientUuid,
            grantUuid
          }
        ).toString()
      );
  }

  addGrantToScope(
    clientUuid: string,
    scopeUuid: string,
    grantUuid: string
  ): Observable<PostAddClientScopeAllowedGrantResponseDto> {
    return this.#httpClient
      .post<PostAddClientScopeAllowedGrantResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_OAUTH_CLIENT_SCOPE_ALLOWED_GRANT.path,
          {
            clientUuid,
            scopeUuid,
            grantUuid
          }
        ).toString(),
        {}
      );
  }

  removeGrantFromScope(
    clientUuid: string,
    scopeUuid: string,
    grantUuid: string
  ): Observable<DeleteRemoveClientScopeAllowedGrantResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientScopeAllowedGrantResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_SCOPE_ALLOWED_GRANT.path,
          {
            clientUuid,
            scopeUuid,
            grantUuid
          }
        ).toString()
      );
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { SsnValidationResponseEntity } from '@core/entities/response/ssn-validation-response.entity';
import { UserVerificationEntity } from '@core/entities/user/user-verification.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { EnvironmentHelper, EnvironmentHelper as EnvHelper } from '@core/helpers/environment.helper';
import { UserStoreService } from '@core/services/stores/user-store.service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, take, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { GetSelfResponseDto } from '@core/models/responses/id-api/get-self/get-self.response-dto';
import idApiConfig from '@config/apis/id/id-api.config';
import { GetUsersResponseDto } from '@core/dtos/id-api/users/get-users.response-dto';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #httpClient = inject(HttpClient);
  #userStore = inject(UserStoreService);

  #ongoingGetSelfRequest?: Observable<GetSelfResponseDto>;

  private _fetchingUser: boolean = false;

  checkValidSsn(ssn: string): Observable<SsnValidationResponseEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('v1/validate/ssn/' + ssn)).pipe(
      map((response: object) => {
        return deserialize(SsnValidationResponseEntity, response);
      }),
    );
  }

  deleteUser(userId: string): Observable<OldUserEntity> {
    return this.#httpClient.delete(EnvironmentHelper.fetchAPIBase('v2/users/' + userId)).pipe(
      map((response: { user: object }) => {
        return deserialize(OldUserEntity, response.user);
      }),
    );
  }

  existsBySsn(ssn: string): Observable<boolean> {
    return this.#httpClient
      .get(EnvHelper.fetchAPIBase('v1/users/exists/by-ssn/' + ssn))
      .pipe(map((response: { exists: boolean }) => response.exists));
  }

  /**
   * Fetches user
   *
   * NB! To fetch logged-in user, use getSelf()
   */
  getUser(userId: string, includeNin = false): Observable<OldUserEntity> {
    const params = includeNin ? { include: 'nin' } : null;
    return this.#httpClient.get(EnvHelper.fetchAPIBase('v2/users/' + userId), { params }).pipe(
      map((response: { user: object }) => {
        return deserialize(OldUserEntity, response.user);
      }),
    );
  }

  getPendingInformationUpdates(oauthClientId?: string): Observable<PendingInformationUpdates> {
    let params = new HttpParams();

    if (oauthClientId) {
      params = params.set('client_id', oauthClientId);
    }

    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase('v1/users/pending-user-info-updates'), {
        params,
      })
      .pipe(
        map((response: { userInfo: object }) => {
          return deserialize(PendingInformationUpdates, response.userInfo);
        }),
      );
  }

  /**
   * Returns local instance of logged-in user, or fetches it from API
   *
   * NB! To fetch other user, use getUser()
   *
   */
  getOldSelf(): Observable<OldUserEntity | null> {
    const user$ = this.#httpClient.get(EnvHelper.fetchAPIBase('v2/users/self')).pipe(
      map((response: { user: object }) => {
        return deserialize(OldUserEntity, response.user);
      }),
      tap((user: OldUserEntity) => {
        this.#userStore.user = user;
      }),
    );

    let userIsUnauthenticated = false;
    const existingUser: OldUserEntity = this.#userStore.user;

    if (existingUser === null && !this._fetchingUser) {
      this._fetchingUser = true;

      return user$.pipe(
        tap(() => {
          this._fetchingUser = false;
        }),
        catchError((error) => {
          if (error.status === 401) {
            userIsUnauthenticated = true;
            this.#userStore.user = null;
          }
          this._fetchingUser = false;
          return of(null);
        }),
        concatMap(() => {
          return this.#userStore.user$;
        }),
        filter((user: OldUserEntity | null) => {
          if (userIsUnauthenticated) {
            return true;
          } else {
            return null !== user;
          }
        }),
      );
    } else {
      return this.#userStore.user$;
    }
  }

  getSelf(): Observable<GetSelfResponseDto> {
    if (this.#ongoingGetSelfRequest) {
      console.log('reusing request'); // TODO: Figure out why not working
      return this.#ongoingGetSelfRequest;
    }

    this.#ongoingGetSelfRequest = this.#httpClient.get<GetSelfResponseDto>(
      `${idApiConfig.baseUrl}${idApiConfig.endpoints.GET_SELF.path}`,
    ).pipe(
      finalize(() => {
        this.#ongoingGetSelfRequest = undefined;
      }),
    );

    return this.#ongoingGetSelfRequest;
  }

  putSelf(user: OldUserEntity, includeNin = false): Observable<OldUserEntity> {
    const params = includeNin ? { include: 'nin' } : null;

    return this.#httpClient
      .put(
        EnvHelper.fetchAPIBase('v1/users/self'),
        {
          user: this._convertToOld(user, 'rawSsn'),
        },
        { params },
      )
      .pipe(
        map((response: { user: object }) => {
          const user = this._convertToNew(response.user);
          this.#userStore.user = user;
          return user;
        }),
      );
  }

  isSelf(userId: string): Observable<boolean> {
    return this.getOldSelf().pipe(
      map((user: OldUserEntity | null) => {
        return user.uuid === userId;
      }),
      take(1),
    );
  }

  postUser(user: OldUserEntity): Observable<OldUserEntity> {
    return this.#httpClient
      .post(EnvHelper.fetchAPIBase('v1/users'), {
        user: this._convertToOld(user, 'rawSsn'),
      })
      .pipe(
        map((response: { user: object }) => {
          return this._convertToNew(response.user);
        }),
      );
  }

  putUser(userId: string, user: OldUserEntity, includeNin = false): Observable<OldUserEntity> {
    const params = includeNin ? { include: 'nin' } : null;

    return this.#httpClient
      .put(
        EnvHelper.fetchAPIBase('v2/users/' + userId),
        {
          user: this._convertToOld(user, 'rawSsn'),
        },
        { params },
      )
      .pipe(
        map((response: { user: object }) => {
          return this._convertToNew(response.user);
        }),
      );
  }

  putUserStatus(userUuid: string, status: string, reason: string): Observable<OldUserEntity> {
    const user$ = this.#httpClient
      .put(EnvHelper.fetchAPIBase('v2/users/' + userUuid + '/status'), {
        status,
        reason,
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(OldUserEntity, response.user);
        }),
      );

    return this.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return user$.pipe(
          tap((user: OldUserEntity) => {
            if (isSelf) {
              this.#userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  putRoles(userUuid: string, enabledRolesUuids: Array<string>): Observable<OldUserEntity> {
    const user$ = this.#httpClient
      .put(EnvironmentHelper.fetchAPIBase('v2/users/' + userUuid + '/roles'), {
        roleUuids: enabledRolesUuids,
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(OldUserEntity, response.user);
        }),
      );

    return this.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return user$.pipe(
          tap((user: OldUserEntity) => {
            if (isSelf) {
              this.#userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  syncUser(user: OldUserEntity): Observable<OldUserEntity> {
    return this.#httpClient
      .post(EnvHelper.fetchAPIBase('v1/users/' + user.uuid + '/sync'), {
        syncSystem: 'fane2',
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(OldUserEntity, response.user);
        }),
      );
  }

  updatePassword(userId: string, password: string, repeatPassword: string, oldPassword?: string): Observable<object> {
    return this.#httpClient.patch(EnvHelper.fetchAPIBase('v2/users/' + userId + '/change-password'), {
      password,
      repeatPassword,
      oldPassword: oldPassword ? oldPassword : null,
    });
  }

  verifyUser(user: OldUserEntity, idType: string): Observable<OldUserEntity> {
    return this.#httpClient
      .post(EnvHelper.fetchAPIBase('v1/user-verifications'), {
        source: idType,
        userUuid: user.uuid,
      })
      .pipe(
        map((response: { userVerification: object }) => {
          const verification = deserialize(UserVerificationEntity, response.userVerification);
          const index = user.verifications.findIndex((v) => v.type === verification.type);
          if (index > -1) {
            user.verifications[index] = verification;
          } else {
            user.verifications.push(verification);
          }
          this.isSelf(user.uuid).subscribe((isSelf: boolean) => {
            if (isSelf) {
              this.#userStore.user = user;
            }
          });
          return user;
        }),
      );
  }

  searchDevelopers(query: string, limit = 10): Observable<GetUsersResponseDto> {
    return this.#httpClient.get<GetUsersResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USERS.path,
        null,
        {
          query,
          role: 'ROLE_DEVELOPER',
          limit,
        },
      ).toString(),
    );
  }

  searchTestUsers(query: string, limit = 10): Observable<GetUsersResponseDto> {
    return this.#httpClient.get<GetUsersResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USERS.path,
        null,
        {
          query,
          limit,
        },
      ).toString(),
    );
  }

  /**
   * Convert from User class to old definition
   *
   * @param newUser
   * @param ssnParam Which of the old ssn params to convert the new nin param to
   */
  private _convertToOld(newUser: OldUserEntity, ssnParam: 'ssn' | 'rawSsn') {
    const user = serialize(newUser);
    user[ssnParam] = newUser.nin;
    user['isTestUser'] = newUser.isTestUser;
    user['bankAccount'] = newUser.bankAccount || null;
    return user;
  }

  /**
   *  Convert to User class from old definition
   */
  private _convertToNew(oldUser: object) {
    const user = deserialize(OldUserEntity, oldUser);
    user.nin = oldUser['nin'] || oldUser['ssn'] || oldUser['rawSsn'];
    user.isTestUser = oldUser['isTestUser'];
    return user;
  }
}

import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ɵFormGroupValue } from '@angular/forms';
import { Role } from '@core/entities/user/role.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { bankAccountValidator } from '@shared/validators/bank-account.validator';
import { NameAndAddressForm } from './types/name-address-form.type';
import { UserPermissionForm } from './types/user-permission-form.type';
import { NamesForm } from './types/user-name-form.type';
import { UserForm } from './types/user-form.type';
import { AddressForm } from './types/address-form.type';
import { EmailForm } from './types/emails-form.type';
import { EmailValidator } from '@shared/validators/email.validator';
import { Email } from '@core/entities/user/email.entity';
import { Phone } from '@core/entities/user/phone.entity';
import { PhoneForm } from './types/phone-form.type';
import { PhoneValidator } from '@shared/validators/phone.validator';
import { LoginForm } from './types/login-form.type';
import { NinValidator } from '@shared/validators/nin.validator';
import { parsePhoneNumber } from 'libphonenumber-js';

@Injectable({ providedIn: 'root' })
export class UserFormBuilder {
  constructor(private _fb: FormBuilder) {}

  UserForm(
    user: OldUserEntity,
    opts = { requireAddress: true, requireName: true, includeEmployment: false },
  ): FormGroup<UserForm> {
    return this._fb.group({
      nameAndAdresses: this._fb.group({
        postalAddresses: this.Addresses(user.postalAddresses, opts.requireAddress),
        names: this.UserNames({ firstName: user.firstName, lastName: user.lastName }, opts.requireName),
      }),
      // Empty array to hold new emails added
      emails: new FormArray([]),
      employment:
        opts.includeEmployment &&
        this._fb.group({
          bankAccount: new FormControl(user.bankAccount, [Validators.required, bankAccountValidator]),
        }),
      // Empty array to hold new phones added
      phones: new FormArray([]),
      roles: this._fb.array<FormGroup<UserPermissionForm>>([]),
      nin: this._fb.control(null),
      isTestUser: this._fb.control(null),
    });
  }

  UserNames(values: ɵFormGroupValue<NamesForm> | null, required = true): FormGroup<NamesForm> {
    return this._fb.group({
      firstName: [values?.firstName, !required ? [] : Validators.required],
      lastName: [values?.lastName, !required ? [] : Validators.required],
    });
  }

  Addresses(values: Array<ɵFormGroupValue<AddressForm>>, required = true): FormArray<FormGroup<AddressForm>> {
    if (!values.length) {
      return this._fb.array([this.Address({}, required)]);
    }

    return this._fb.array(values.map((address) => this.Address(address, required)));
  }

  Address(values: ɵFormGroupValue<AddressForm>, required = true): FormGroup<AddressForm> {
    return this._fb.group({
      primary: true as boolean,
      postalArea: [values.postalArea, !required ? [] : Validators.required],
      country: [values.country, !required ? [] : Validators.required],
      line1: [values.line1, !required ? [] : Validators.required],
      line2: values.line2,
      line3: values.line3,
      countryCode: values?.countryCode,
      countryObject: [null],
      postalCode: [values.postalCode, !required ? [] : [Validators.required, Validators.minLength(4)]],
      uuid: values?.uuid,
    });
  }

  NameAndAddress(values: ɵFormGroupValue<NameAndAddressForm> | null, required = true): FormGroup<NameAndAddressForm> {
    return this._fb.group({
      names: this.UserNames(values?.names ?? {}, required),
      postalAddresses: this.Addresses(values?.postalAddresses ?? [], required),
    });
  }

  Roles(value: ɵFormGroupValue<UserPermissionForm>, existingRoles: Role[]): FormArray<FormGroup<UserPermissionForm>> {
    if (value.children?.length) {
      return new FormArray(value.children.map((child) => this.Role(child, existingRoles)));
    } else {
      return new FormArray([]);
    }
  }

  Role(value: ɵFormGroupValue<UserPermissionForm>, existingRoles: Role[]): FormGroup<UserPermissionForm> {
    const isChecked = existingRoles.some((role) => role.uuid === value.uuid);
    return this._fb.group({
      uuid: value.uuid,
      children: this.Roles(value, existingRoles),
      description: value.description,
      isChecked,
      name: value.name,
      roleName: value.roleName,
    });
  }

  Emails(emails: Email[], required = true): FormArray<FormGroup<EmailForm>> {
    if (!emails.length) {
      return this._fb.array([this.Email(null, required, emails)]);
    } else {
      return this._fb.array(emails.map((email) => this.Email(email, required, emails)));
    }
  }

  Email(
    email: Email = null,
    required = true,
    existingEmails = Array<Email>(),
    autofocus = false,
  ): FormGroup<EmailForm> {
    const validators = [EmailValidator.parentHasUuid, EmailValidator.duplicateEmail(existingEmails)];
    if (required) {
      validators.push(Validators.required);
    }

    return this._fb.group({
      autofocus,
      created: email?.createdAt,
      emailAddress: [email?.emailAddress, validators],
      uuid: email?.uuid,
    });
  }

  phones(phones: Phone[], required = true): FormArray<FormGroup<PhoneForm>> {
    if (!phones?.length) {
      return this._fb.array([this.phone(null, required)]);
    } else {
      return this._fb.array(phones.map((phone) => this.phone(phone, required)));
    }
  }

  phone(
    phone: Phone = null,
    required = true,
    existingPhones = Array<Phone>(),
    autofocus = false,
    defaultCountryCode = '47',
  ): FormGroup<PhoneForm> {
    const numberValidation = [PhoneValidator.unsaved, PhoneValidator.duplicateNumber(existingPhones)];
    if (required) {
      numberValidation.push(Validators.required);
    }

    const phoneNumber = phone
      ? parsePhoneNumber(phone.phoneNumber)
      : { countryCallingCode: defaultCountryCode, nationalNumber: '' };

    return this._fb.group({
      autofocus,
      created: phone?.createdAt ?? null,
      countryCode: phoneNumber.countryCallingCode,
      number: [phoneNumber.nationalNumber, numberValidation],
      uuid: phone?.uuid ?? null,
    });
  }

  loginForm(): FormGroup<LoginForm> {
    return this._fb.group({
      phone: this.phoneLogin(),
      ssn: ['', [Validators.required, NinValidator.ninSyntaxInvalid]],
    });
  }

  phoneLogin(): FormGroup<PhoneForm> {
    return this._fb.group({
      autofocus: [null],
      created: [null],
      uuid: [null],
      countryCode: '47',
      number: ['', Validators.required],
    });
  }
}

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MainMenuItem } from '@core/entities/site/main-menu.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { MainMenuContextInterface } from '@shared/components/main-menu/main-menu-context-interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnDestroy, OnInit {
  @Output()
  clicked: EventEmitter<MainMenuItem> = new EventEmitter<MainMenuItem>();

  @Input()
  @HostBinding('class.expanded')
  isExpanded: boolean = false;

  @Input() menuContext: MainMenuContextInterface = null;

  iconSize: 26;
  items: MainMenuItem[] = [];
  user: OldUserEntity;

  private readonly _onDestroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this.menuContext
      .getMainMenuItems()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((menuItems) => {
        this.items = menuItems;
      });
  }

  /**
   * If we are using a collapsed (mobile) menu this will
   * return null for parent items, to let them expand without navigating.
   *
   * If we are using an expanded menu parent items will
   * return its own route if exits, else its first child's route.
   *
   * @param {MainMenuItem} item
   * @returns {string}
   */
  getRoute(item: MainMenuItem): string | null {
    if (item.hasChildren()) {
      if (this.isExpanded) {
        return item.route ? item.route : item.children[0].route;
      }
      return null;
    } else {
      return item.route ? item.route : null;
    }
  }

  /**
   * @param {MainMenuItem} item
   */
  itemClicked(item: MainMenuItem) {
    this.menuContext.changeActiveTo(item);

    if (item.method) {
      item.method();
    }

    this.clicked.emit(item);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { UserStoreService } from '@core/services/stores/user-store.service';
import { UserService } from '@core/services/user/user.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private _userStore: UserStoreService,
  ) {}

  /**
   * @param onlyCollections
   */
  clearUser(onlyCollections = false) {
    if (!onlyCollections) {
      this._userStore.user = null;
    }
  }

  impersonateUser(userId: string): Observable<OldUserEntity> {
    return this._http.post(EnvironmentHelper.fetchAPIBase('v2/users/' + userId + '/impersonate'), {}).pipe(
      map((response: { user: object }) => {
        return deserialize(OldUserEntity, response.user);
      }),
      tap((user: OldUserEntity) => {
        this._userStore.impersonating = true;
        this._userStore.user = user;
      }),
    );
  }

  instantiateUser(): Observable<OldUserEntity | null> {
    return this._userService.getOldSelf().pipe(take(1));
  }

  isImpersonating(): Observable<boolean> {
    return this._userStore.isImpersonating();
  }

  stopImpersonatingUser(): Observable<OldUserEntity | null> {
    return this._http.post(EnvironmentHelper.fetchAPIBase('v2/users/stop-impersonating'), {}).pipe(
      map((response: { user: object }) => {
        return deserialize(OldUserEntity, response.user);
      }),
      tap((user: OldUserEntity) => {
        this._userStore.impersonating = false;
        this._userStore.user = user;
      }),
      catchError((error: any) => {
        if (error.status === 400 && error.error.error === 'NOT_IMPERSONATING') {
          this._userStore.impersonating = false;
          return of(null);
        }

        return throwError(() => error);
      }),
    );
  }
}
